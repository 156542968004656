/* eslint-disable sonarjs/cognitive-complexity */
import { InsuranceClaimPageType_insuranceClaimPage_personalizedClaimLinksCollection_items } from "@containers/InsuranceClaimPage/queries/contentfulTypes/InsuranceClaimPageType";
import { IVImage } from "@utils/imageHelpers/imageHelper";

import { Context } from "types/context/Context";

import InsuranceAbout from "./InsuranceStateTypes/InsuranceAbout";
import InsuranceClaim from "./InsuranceStateTypes/InsuranceClaim";
import InsuranceNoAudience from "./InsuranceStateTypes/InsuranceNoAudience";
import InsuranceNotCovered from "./InsuranceStateTypes/InsuranceNotCovered";

export interface InsuranceInformationArea {
  state: "about" | "claim";
  covered: boolean;
  hasAudience: boolean;
  coveredHeading: string;
  notCoveredHeading: string;
  noAudienceFallbackHeading: string;
  bulletListItems?: string[];
  informationMessage?: any;
  links: InsuranceClaimPageType_insuranceClaimPage_personalizedClaimLinksCollection_items[];
  context: Context;
  image: IVImage;
  contentfulPreviewEntryId?: string;
}

const InsuranceInformationArea = ({
  state,
  covered,
  hasAudience,
  coveredHeading,
  notCoveredHeading,
  noAudienceFallbackHeading,
  bulletListItems,
  informationMessage,
  links,
  context,
  image,
  contentfulPreviewEntryId,
}) => {
  if (!hasAudience) {
    return (
      <InsuranceNoAudience
        bulletListItems={bulletListItems}
        image={image}
        heading={noAudienceFallbackHeading}
        contentfulPreviewEntryId={contentfulPreviewEntryId}
        contentfulPreviewUspsFieldId={state === "about" ? "uspar" : "usps"}
      />
    );
  }

  if (!covered) {
    return (
      <InsuranceNotCovered
        informationMessage={informationMessage}
        image={image}
        heading={notCoveredHeading}
        contentfulPreviewEntryId={contentfulPreviewEntryId}
        contentfulPreviewHeadingFieldId={"fallbackUspHeading"}
        contentfulPreviewUspsFieldId={state === "about" ? "uspar" : "usps"}
      />
    );
  }

  switch (state) {
    case "about":
      return (
        <InsuranceAbout
          bulletListItems={bulletListItems}
          informationMessage={informationMessage}
          image={image}
          links={links}
          context={context}
          heading={coveredHeading}
          contentfulPreviewEntryId={contentfulPreviewEntryId}
          contentfulPreviewHeadingFieldId={"uspHeading"}
          contentfulPreviewUspsFieldId={"uspar"}
        />
      );
    case "claim":
      return (
        <InsuranceClaim
          bulletListItems={bulletListItems}
          informationMessage={informationMessage}
          links={links}
          context={context}
          image={image}
          heading={coveredHeading}
          contentfulPreviewEntryId={contentfulPreviewEntryId}
          contentfulPreviewHeadingFieldId={"uspHeading"}
          contentfulPreviewUspsFieldId={"usps"}
        />
      );
    default:
      return (
        <InsuranceNoAudience
          bulletListItems={bulletListItems}
          image={image}
          heading={noAudienceFallbackHeading}
          contentfulPreviewEntryId={contentfulPreviewEntryId}
          contentfulPreviewUspsFieldId={"uspar"}
        />
      );
  }
};

export default InsuranceInformationArea;
