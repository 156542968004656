import { useRouter } from "next/router";

import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import Text from "@afa-shared/afa-components/dist/Text";
import PageInformation from "@components/PageInformation/PageInformation";
import PrintButton from "@components/PrintButton";
import RichTextRenderer from "@components/RichTextRenderer";
import StandardPageLeftMenu from "@components/navigation/StandardPageLeftMenu";
import ArticlePageWrapper from "@containers/Layout/PageWrapper/ArticlePageWrapper";
import { defaultContext } from "@utils/contextHelper/getInitialContext";
import { shouldRemoveBottomMargin } from "@utils/layoutHelpers/layoutMarginHelper";
import { getContentAreaMaxWidth } from "@utils/pageHelper";
import { shouldUseReCapcha } from "@utils/reCapchaHelper";

import { Context } from "types/context/Context";

import CFContentArea from "../../components/cFContentArea";
import easyStyles from "../../styles/easyToRead.module.css";
import pageStyles from "../../styles/page.module.css";
import { StandardPageType_standardpage } from "./queries/contentfulTypes/StandardPageType";

export interface ExtendedStandardPage extends StandardPageType_standardpage {
  latestReviewDate: string;
  pageUpdateDate: string;
  pageLanguage: string;
}

type StandardPagePropType = {
  pageData: ExtendedStandardPage;
  slug?: string;
  leftMenuItems?: any;
  accessibilityList?: any;
  children?: React.ReactNode;
};

const StandardPage = (props: StandardPagePropType) => {
  const router = useRouter();
  const currentUrl = router.asPath;
  const standardPage = props.pageData;
  const imageData = standardPage?.ivMainImage?.[0];

  const isCookiePolicyPage = props.slug === "kakor";
  const isContactFormPage = currentUrl.includes("kundtjanst/skicka-meddelande");

  const myList = [];
  interface TreeStructureItem {
    [key: string]: any;
  }

  function GetLeftMenuStartNode(
    treeStructureItem: TreeStructureItem | TreeStructureItem[],
    keyToFind: string,
    valueToFind: string
  ): void {
    if (Array.isArray(treeStructureItem)) {
      treeStructureItem.forEach((item) => GetLeftMenuStartNode(item, keyToFind, valueToFind));
    } else {
      processTreeStructureItem(treeStructureItem, keyToFind, valueToFind);
    }
  }

  function processTreeStructureItem(
    item: TreeStructureItem,
    keyToFind: string,
    valueToFind: string
  ): void {
    for (const prop in item) {
      if (prop === keyToFind && item[prop] === valueToFind) {
        myList.push(item);
      }
      if (typeof item[prop] === "object") {
        GetLeftMenuStartNode(item[prop], keyToFind, valueToFind);
      }
    }
  }

  const context: Context = { ...defaultContext };
  const leftMenuReference = standardPage?.leftMenuReference?.sys?.id;
  if (leftMenuReference && props?.leftMenuItems) {
    GetLeftMenuStartNode(JSON.parse(props.leftMenuItems), "id", leftMenuReference);
  }

  const hasLeftMenu = myList.length > 0;

  const useReCapcha = shouldUseReCapcha(
    [].concat(
      standardPage?.mainAreaCollection?.items,
      standardPage?.mainAreaBottomCollection?.items
    )
  );
  const removeLayoutBottomMargin = shouldRemoveBottomMargin(
    standardPage?.mainAreaBottomCollection?.items
  );

  const pageId = standardPage?.linkedFrom?.pageCollection?.items?.find((item) => item).sys?.id;

  return (
    <ArticlePageWrapper
      imageData={imageData}
      topImageText={standardPage?.topImageText}
      columns={8}
      relatedContentHeading={standardPage?.relatedContentHeading}
      relatedContentCollection={standardPage?.relatedContentCollection}
      relatedContentSmallImages={standardPage?.relatedContentSmallImages}
      context={context}
      useReCapcha={useReCapcha}
      shouldRemoveBottomMargin={removeLayoutBottomMargin}
      contentfulPreviewEntryId={standardPage.sys.id}
      contentfulPreviewImageFieldId={"ivMainImage"}
      contentfulPreviewImageTextFieldId={"topImageText"}
      contentfulPreviewRelatedContentHeadingFieldId={"relatedContentHeading"}
      className={hasLeftMenu && pageStyles.withLeftMenu}
    >
      {hasLeftMenu && (
        <Grid
          mobile={12}
          desktop={4}
          desktopLarge={3}
          className={`${pageStyles.menuGrid}`}
          maxWidth={362}
        >
          <StandardPageLeftMenu
            leftmenuItems={myList}
            accessibilityList={props.accessibilityList}
            contentfulPreviewEntryId={standardPage.sys.id}
            contentfulPreviewLeftMenuFieldId={"leftMenuReference"}
          />
        </Grid>
      )}
      <Grid
        lang={standardPage?.pageLanguage === "Arabiska" ? "ar" : undefined}
        dir={standardPage?.pageLanguage === "Arabiska" ? "rtl" : undefined}
        container
        mobile={12}
        desktop={8}
        desktopLarge={8}
        className={`${hasLeftMenu ? pageStyles.leftmenuMaincolumn : ""} ${
          isCookiePolicyPage ? "cc-hidden" : ""
        } ${standardPage.easyToRead ? easyStyles.easyToRead : ""} ${
          standardPage?.pageLanguage === "Arabiska" ? pageStyles.arabic : ""
        }
          ${standardPage?.pageLanguage === "Arabiska" ? "arabic" : ""}`}
      >
        <Heading
          variant="h1"
          data-contentful-entry-id={standardPage.sys.id}
          data-contentful-field-id="heading"
        >
          {standardPage?.heading}
        </Heading>
        {standardPage?.preamble && (
          <Text
            variant={"preamble"}
            children={standardPage?.preamble}
            data-contentful-entry-id={standardPage.sys.id}
            data-contentful-field-id="preamble"
          />
        )}

        <div className={pageStyles.contentAreaGrid}>
          {standardPage?.mainBody?.json && (
            <div data-contentful-entry-id={standardPage.sys.id} data-contentful-field-id="mainBody">
              <RichTextRenderer
                json={standardPage?.mainBody?.json}
                links={standardPage?.mainBody?.links}
                pageTextArea
              />
            </div>
          )}

          {standardPage?.mainAreaCollection?.items?.length > 0 && (
            <CFContentArea
              desktopColumns={1}
              mobileColumns={1}
              tabletColumns={1}
              contentAreaItems={standardPage?.mainAreaCollection?.items}
              maxWidth={getContentAreaMaxWidth(8)}
              context={context}
            />
          )}

          {standardPage?.mainBodyBottom?.json && (
            <div
              data-contentful-entry-id={standardPage.sys.id}
              data-contentful-field-id="mainBodyBottom"
            >
              <RichTextRenderer
                json={standardPage?.mainBodyBottom?.json}
                links={standardPage?.mainBodyBottom?.links}
                pageTextArea
              />
            </div>
          )}

          {standardPage?.mainAreaBottomCollection?.items?.length > 0 && (
            <CFContentArea
              desktopColumns={1}
              mobileColumns={1}
              tabletColumns={1}
              contentAreaItems={standardPage?.mainAreaBottomCollection?.items}
              maxWidth={getContentAreaMaxWidth(8)}
              context={context}
            />
          )}
          {props.children}
        </div>
        <div dir={"ltr"} lang={"sv"}>
          {!isContactFormPage && <PrintButton />}
          {(standardPage?.latestReviewDate || standardPage?.pageUpdateDate) && (
            <PageInformation
              pageId={pageId}
              latestReviewDate={standardPage?.latestReviewDate}
              pageUpdateDate={standardPage?.pageUpdateDate}
            />
          )}
        </div>
      </Grid>
    </ArticlePageWrapper>
  );
};

export default StandardPage;
